<template>
    <div id="app">
        <router-view />
    </div>
</template>

<style src="./assets/sass/style.scss" lang="scss"></style>

<script>

export default {
    name: 'App'
}
</script>