import Vue from 'vue'
import Vuex from 'vuex'
//import VuexPersistence from 'vuex-persist'
import modules from './modules'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
	modules,
	actions: {
		reset({ commit }) {
			// resets state of all the modules
			// this.$store.dispatch('reset')
			Object.keys(modules).forEach(moduleName => {
				commit(`${moduleName}/RESET`)
			})
		}
	},t: debug,
	//plugins: [new VuexPersistence().plugin],
})
