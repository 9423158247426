import Vue from 'vue'

// State object
const initialState = () => ({
    user: []
})

const state = initialState()

// Getter functions
const getters = {
    user: state => Vue.auth.user()
}

// Actions 
const actions = {
    fetch(data) {        
        commit('FETCH', data)
    },
    refresh(data) {
        commit('REFRESH', data)
    },
    login({ commit }, data ) {
        commit('LOGIN', data)
    },
    register({ commit }, data ) {
        commit('REGISTER', data)
    },
    logout({ commit } ) {
        commit('LOGOUT' )
    }
}

// Mutations
const mutations = {
    RESET(state) {
        const newState = initialState()
        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
    FETCH(data) {
        return Vue.auth.fetch(data)
    },
    REFRESH(data) {
        return Vue.auth.refresh(data)
    },
    LOGIN(state, data) {
        data = data || {}

        return new Promise((resolve, reject) => {
            Vue.auth.login({
                url: 'auth/login',
                //body: data.body, // VueResource
                data: data.data, // Axios
                remember: data.remember,
                staySignedIn: data.staySignedIn,
            })
            .then((res) => {
                if (data.remember) {
                    Vue.auth.remember(JSON.stringify({
                        name: state.getters.user.username
                    }))
                }

                Vue.router.push({
                    name: 'dashboard'
                })

                resolve(res)
            }, reject)
        })
    },
    REGISTER(state, data) {
        data = data || {}

        return new Promise((resolve, reject) => {
            Vue.auth.register({
                url: 'auth/register',
                //body: data.body, // VueResource
                data: data.data, // Axios
                autoLogin: false,
            })
            .then((res) => {
                if (data.autoLogin) {
                    this.dispatch('auth/login', data).then(resolve, reject)
                }
            }, reject)
        })
    },
    LOGOUT(state) {
        return Vue.auth.logout()
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}