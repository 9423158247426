import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
      path: '/',
      name: 'Home',
      component: () => import('../views/Home.vue')
  },
  {
      path: '/aviso-legal',
      name: 'aviso-legal',
      component: () => import('../views/legal/Legal.vue')
  },
  {
      path: '/politica-de-privacidad',
      name: 'privacidad',
      component: () => import('../views/legal/Privacidad.vue')
  },
  {
      path: '/politica-de-cookies',
      name: 'cookies',
      component: () => import('../views/legal/Cookies.vue')
  }
]

const router = new VueRouter({
    // mode: 'history',
    history: true,
    routes
})

export default router