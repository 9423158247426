import Vue from 'vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

axios.defaults.baseURL = '/api/'
//axios.defaults.baseURL = `${process.env.VUE_APP_BASE_URL}/api`
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

Vue.use(VueAxios, axios)
window.axios = require('axios')
//Model.$http = axios

export default {
    root: process.env.VUE_APP_API_URL
}