import Vue from 'vue'
import App from './App.vue'
import http from './config/http'
import store from './store'
import router from './router'

// Plugins
import BootstrapVue from 'bootstrap-vue'
import VueSmoothScroll from 'vue2-smooth-scroll'

import Vuelidate from 'vuelidate'

Vue.config.productionTip = false

Vue.use(BootstrapVue)

Vue.use(VueSmoothScroll, {
    duration: 600,
    updateHistory: false
})

Vue.use(Vuelidate)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

new Vue({
    store,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
